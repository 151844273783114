$textColor: #303645;

@import "./Styles/layout/CheckEmailPage.scss";
@import "./Styles/layout/Listing.scss";
@import "./Styles/base.scss";

* {
  box-sizing: border-box;
}

@media only screen and (min-width: 600px) {
  html,
  body {
    overflow: hidden;
    height: 100%;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-size: 1rem;
}

a {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

#root {
  height: 100%;
}

label {
  font-size: 0.875rem;
  margin: 0px 0px 0px !important;
  display: block;
  width: 100%;
}

.form-control-plaintext {
  font-weight: 600;
  padding: 0px !important;
}

.custom-checkbox {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.custom-control-label {
  padding-top: 3px;
}

.custom-control-input {
  z-index: 10;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-group-center .input-group-text {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

.navbar-brand {
  align-self: center;
}

.logo {
  max-width: 200px;
  width: 100%;
}

.table thead th {
  border-top: none;
}

.table td:first-child,
.table th:first-child {
  padding-left: 15px;
}

.table td:last-child,
.table th:last-child {
  padding-right: 15px;
}

.tree-chart .node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.tree-chart .node text {
  font: 12px sans-serif;
}

.tree-chart .node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

.tree-chart .link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.tree-chart .chart label {
  display: flex;
}

/* .blood-quantum-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 8.75rem;
}

.blood-quantum-list span {
  float: right;
} */

.index-table {
  table-layout: fixed;
}

.index-table thead th {
  font-size: 0.75rem;
  font-weight: normal;
  text-align: left;
  background-color: #fff;
  padding: 8px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.index-table thead th:first-of-type {
  border-left: none;
}

.index-table tr:hover td {
  background-color: #e8e6e4;
}

.index-table tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.index-table tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.index-table tbody a:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.index-table tbody a:nth-of-type(even) {
  background-color: #ffffff;
}

.index-table td {
  font-size: 13px;
  font-weight: normal;
  text-align: left;
  padding: 8px;
  overflow: hidden;
  white-space: nowrap;
  border: none;
  border-bottom: 1px solid #eee;
  transition: background 0.4s 0.02s;
  vertical-align: middle;
}

.index-table td:last-of-type {
  width: 100px;
  text-align: right;
}

/* START Star Chart */
#force-directed text {
  fill: rgb(34, 34, 34);
}
#force-directed text:hover {
  cursor: pointer;
}

#force-directed .male {
  fill: #6d90e8;
}

#force-directed .female {
  fill: #e8786d;
}

#force-directed rect {
  rx: 3;
  ry: 3;
  stroke: rgb(82, 82, 82);
  stroke-width: 0.5;
}

#force-directed line {
  stroke: rgb(82, 82, 82);
  stroke-width: 1;
}

#force-directed text {
  font-size: 11px;

  text-anchor: middle;
  alignment-baseline: middle;
}

#force-directed text.name {
  font-weight: bold;
}

#force-directed,
#family-tree,
#sunburst {
  height: 800px;
  background: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
}
/* END Star Chart */

/* START Star Chart getting 100% height */
.tab-pane.active,
.tab-pane.active #family,
.tab-pane.active #family .tab-content,
.tab-pane.active #family .tab-content #force-directed {
  height: 100%;
}
/* END Star Chart getting 100% height */

// .card {
//   border: none !important;
//   background-color: transparent !important;
//   border-radius: 0px !important;
// }

// .card-header {
//   font-weight: bold;
//   font-size: 1.4rem;
//   color: rgba(0,0,0,.65) !important;
//   background-color: transparent !important;
//   display: flex !important;
//   flex-direction: row !important;
//   align-items: center !important;
//   border-bottom: none !important;
// }

// .card-body {
//   padding: .5rem 1.25rem 1.25rem !important;
// }

.backlink-icn {
  font-size: 0.75rem;
  margin-right: 6px;
  position: relative;
  top: -1px;
}

.react-select-sm-container {
  font-size: 14px;
}

.react-select-sm-container .react-select-sm__control {
  min-height: 31px;
}

.react-select-sm-container .react-select-sm__indicator {
  padding: 2px;
  color: black;
}

.react-select-sm-container .react-select-sm__option {
  padding: 2px 8px;
}

.react-select-sm-container .react-select-sm__menu {
  z-index: 100;
}

.hidden {
  display: none;
}

.table-addresses .cell-actions {
  text-align: right;
  width: 120px;
}

.table-addresses .table-btn {
  margin: 0 4px;
}

.table-addresses .cell-address {
  width: 300px;
}

.active-address {
  color: #5ba64c;
}

.inactive-address {
  color: #c35b51;
}

.chart-selected {
  color: "#5393dc";
  text-decoration: underline;
  padding-bottom: 3px;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.min-height-100 {
  min-height: 100%;
}

.center-text {
  text-align: center;
}

.chart-align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.error-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35rem;
}

.error-text-large {
  font-size: 8rem;
}

.error-text-mid {
  font-size: 2.5rem;
}

/* STARCHART FIX */
.enrollment-content {
  position: relative;
  height: 100%;
}

#sunburst {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.not-yet {
  display: none !important;
}

.modal-footer .btn {
  min-width: 100px;
}

.btn-group {
  margin: 0px 3px !important;
}

.details-address span {
  display: block;
}

.card .match-card-buttons {
  color: rgba(0, 0, 0, 0.8) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.action-group {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

div.tooltip {
  position: absolute;
  text-align: center;
  padding: 0.5rem 2rem;
  background: rgb(238, 238, 238);
  border-radius: 2px;
  pointer-events: none;
}

.chart-full-height {
  height: 100%;
}

.case-status-btn {
  margin: 4px;
}

.person-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("asset/bg_page_header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Login Screen Styles */
.login_screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem;
  background-color: #223b53;
  background-image: url("asset/bg_dancer.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login_logo {
  width: 5rem;
  height: 5rem;
  margin-bottom: 1rem;
}

.login_form {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  margin: 0;
}

.login_powered_by {
  font-size: 4vw;
  text-align: center;
  margin: 0;
  opacity: 0.2;
  -webkit-touch-callout: none;
  user-select: none;
}

.listing-header-actions-container {
  font-weight: bold;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.65);
  margin: 1rem 0;
}

@media only screen and (min-width: 360px) {
  .login_form {
    padding: 2rem;
    margin: 2rem 0;
  }

  .login_help {
    margin: auto auto 0;
  }

  .login_powered_by {
    margin: 1rem 0;
  }
}

@media only screen and (min-width: 28.125rem) {
  .login_logo {
    width: 8.125rem;
    height: 8.125rem;
    margin-bottom: 2rem;
  }

  .login_form_title {
    font-size: 3rem;
  }

  .login_form_prompt {
    font-size: 1rem;
    margin: 1rem 0;
  }

  .login_help {
    font-size: 1rem;
  }

  .login_powered_by {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 45rem) {
  .login_screen {
    display: grid;
    grid-template-rows: 1fr 4rem;
    justify-content: center;
    align-items: center;
  }

  .login_logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }

  .login_form {
    width: 30rem;
  }

  .login_help {
    position: absolute;
    width: auto;
    top: 2rem;
    right: 2rem;
  }
}

@media only screen and (max-height: 35rem) {
  .login_screen {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .login_logo {
    position: static;
  }

  .login_help {
    position: static;
    margin-top: 3rem;
  }

  html,
  body {
    overflow: auto;
  }
}
