.listing-container {
  display: flex;
  justify-content: center;
}

.listing {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.listing__item {
  display: table-row;
  color: $textColor;
  border-collapse: collapse;
  font-size: 0.85rem;
  line-height: 0.5rem;
  background: #edeff3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing__item:nth-child(odd) {
  background: #f6f6f9;
}

.listing__item:hover {
  background-color: #3a81c6;
  color: white;
  text-decoration: none;
}

.listing__header {
  display: table-row;
  font-weight: 600;
  font-size: 0.85rem;
  background: #ffffff;
}

.listing__header-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing__item-data {
  line-height: 0.75rem;
  font-size: 0.75rem;
  border-bottom: 1px solid #e4e8ea;
}

.listing__item-data,
.listing__header-label {
  display: table-cell;
  padding: 1em;
}
.listing__header-label {
  padding: 1em 0.5rem;
}
.table-head {
  font-weight: bold;
}

/* Large tablet: 1023px - 1200px*/
@media only screen and (max-width: 1200px) {
}

/* Small tablet to large tablet: 767px - 1023px */
@media only screen and (max-width: 1023px) {
}

/* small phones to small tablets: 480px - 767px */
@media only screen and (max-width: 767px) {
}

/* small phones: 0 - 480px */
@media only screen and (max-width: 480px) {
}
