// Bulma variable overrides
// @see https://bulma.io/documentation/customize/variables/

// 1. Import the initial variables:
@import "bulma/sass/utilities/initial-variables.sass";
@import "bulma/sass/utilities/functions.sass";

// 2. Override initial variables:
// @see https://bulma.io/documentation/customize/variables/#initial-variables

// Colors
$blue: #28629b;
$orange: #ff5c00;

// 3. Override derived variables:
// @see https://bulma.io/documentation/customize/variables/#derived-variables

// Colors
$primary: $blue;

// Custom Colors
$secondary: $orange;
$secondary-invert: findColorInvert($secondary);
$muted: $grey-light;
$muted-invert: findColorInvert($muted);

// 4. Override generic variables:
// @see https://bulma.io/documentation/customize/variables/#generic-variables

// 5. Override element and component variables:

// Modal
// @see https://bulma.io/documentation/components/modal/#variables

// Bulma's .modal clashes with Bootstrap's .modal, so we have to increase the zIndex while we're
// still using the Bootstrap modal.
// TODO: Remove this once all Bootstrap Modal components have been removed.
$modal-z: 1050;

// 6. Add new color variables to the color map:
@import "bulma/sass/utilities/derived-variables.sass";
$addColors: (
  "secondary": (
    $secondary,
    $secondary-invert,
  ),
  "muted": (
    $muted,
    $muted-invert,
  ),
);
$colors: map-merge($colors, $addColors);

// 7. Import the rest of Bulma:
@import "bulma/bulma.sass";

.main {
  margin: auto;
  max-width: 900px;
  padding: 2rem;
}

h1 {
  margin-bottom: 20px;
  text-align: center;
}

.lead-text {
  margin-bottom: 60px;
  text-align: center;
}

progress {
  display: block;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    vertical-align: bottom;
  }

  th { 
    border-bottom: 2px solid black;
    text-align: left;
  }

  .bio {
    vertical-align: top;
  }
  
  td {
    padding-left: 1rem;
    padding-bottom: 0.25rem;
  }

  .grand {
    padding-top: 2rem;
  }

  .line-bottom {
    border-bottom: 1px solid black;
  }

  .line-left-down {
    border-left: 1px solid black;
  }

  .line-left-up {
    border-left: 1px solid black;
  }

  .line-left {
    border-left: 1px solid white;
  }
}
